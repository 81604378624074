import React from 'react';
import './headerstyle.css'; // Importa el CSS
import miImagen from '../Images/logo_aacd557.png'; // Asegúrate de cambiar esto por el path real de tu imagen

const Header = () => {
    return (
        <div className="header-container">
            <img src={miImagen} alt="Descripción" />
        </div>
    );
};

export default Header;
