import React from 'react';
import './aceptarstyle.css'; // Importa el archivo CSS
import gifImagen from '../Images/aceptaranimado.gif'; // Cambia la ruta a tu GIF

const Aceptar = () => {
    const url = "https://docs.google.com/forms/d/e/1FAIpQLSeANgPVkZtVaJSnC9R244ue4mg4Mykdjb_pCdUQz3_zOyCg7Q/viewform?usp=sf_link";

    return (
        <div className="boton-centrado">
            <button onClick={() => window.location.href = url} className="boton-aceptar">
                <img src={gifImagen} alt="Aceptar" />
                <span>Inscripción</span>
            </button>
        </div>
    );
};

export default Aceptar;
