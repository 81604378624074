import React from 'react';
import './logotorneostyle.css'; // Asegúrate de crear este archivo CSS
import logoTorneoImagen from '../Images/logotorneo.jpeg'; // Cambia esto por la ruta real de tu imagen de logo

const LogoTorneo = () => {
    return (
        <div className="logo-torneo-contenedor">
            <img src={logoTorneoImagen} alt="Logo del Torneo" />
        </div>
    );
};

export default LogoTorneo;
