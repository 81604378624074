import React from 'react';
import './App.css';
import Fondo from './Component/fondo'; // Asegúrate de que la ruta sea correcta
import Header from './Component/header'; // Asegúrate de que la ruta sea correcta 
import Slide from './Component/slide'; // Asegúrate de que la ruta sea correcta
import Aceptar from './Component/aceptar';
import LogoTorneo from './Component/logotorneo';
import FloatingContactBar from './Component/redes';
function App() {
  return (
    <div className="App">
  <Header /> {/* Solo se muestra el componente Header */}
      <Fondo /> {/* Solo se muestra el componente Fondo */}
      <Slide /> {/* Solo se muestra el componente Slide */}
      <Aceptar /> {/* Solo se muestra el componente Aceptar */}
      <LogoTorneo /> {/* Solo se muestra el componente LogoTorneo */}
      <FloatingContactBar /> {/* Solo se muestra el componente FloatingContactBar */}
    </div>
  );
}

export default App;

