import React, { useState, useEffect } from 'react';
import './redesstyle.css';
import DiscordIcon from '../Images/discord.svg';
import TiktokIcon from '../Images/tiktok.svg';
import FacebookIcon from '../Images/facebook.svg';

const FloatingContactBar = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScroll > lastScrollTop) {
            // Scroll hacia abajo
            setIsVisible(true);
        } else {
            // Scroll hacia arriba
            setIsVisible(false);
        }
        setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll); // Para evitar valores negativos
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <div className={`floating-contact-bar ${isVisible ? '' : 'hide'}`}>
            <a href="tu-enlace-discord" target="_blank" rel="noopener noreferrer">
                <img src={DiscordIcon} alt="Discord" />
            </a>
            <a href="tu-enlace-tiktok" target="_blank" rel="noopener noreferrer">
                <img src={TiktokIcon} alt="TikTok" />
            </a>
            <a href="tu-enlace-facebook" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="Facebook" />
            </a>
        </div>
    );
};

export default FloatingContactBar;

