import React, { useState, useEffect } from 'react';
import './slidestyle.css';
import image1 from '../Images/lan-left_2dd7836.png'; // Tus imágenes
import image2 from '../Images/lan-right3_441f486.png';

const Slide = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Manejador de eventos para el scroll
    const onScroll = () => {
        const scrollPosition = window.scrollY; // Obtiene la posición actual del scroll
        // Determina si las imágenes deben ser visibles
        if (scrollPosition > 300) { // Ajusta este valor según tus necesidades
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Agrega el event listener al montar el componente y lo remueve al desmontarlo
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div className={`slide-container ${isVisible ? 'visible' : ''}`}>
            <img src={image1} alt="Imagen 1" className="slide-image image1" />
            <img src={image2} alt="Imagen 2" className="slide-image image2" />
        </div>
    );
};

export default Slide;
