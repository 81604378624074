import React from 'react';
import './fondostyle.css'; // Asegúrate de crear este archivo CSS
import miImagen from '../Images/chica.png'; // Reemplaza con el path a tu imagen

const Fondo = () => {
    return (
        <div className="contenedor-fondo">
            <img src={miImagen} alt="Descripción" className="imagen-izquierda"/>
        </div>
    );
};

export default Fondo;
